<template>
    <v-sheet
        min-height="100"
        class="fill-height"
        width="100%"
        color="transparent"
    >
        <v-lazy
            :v-model="client"
            :options="{
                threshold: 0.5,
            }"
            class="fill-height"
        >
            <v-list-item
                color="primary"
                three-line
                link
                @click="openDialog"
                :to="{
                    name: 'client',
                    params: { id: client.id, client },
                }"
            >
                <v-list-item-avatar size="44" class="my-auto">
                    <v-img
                        rounded
                        contain
                        :aspect-ratio="1 / 1"
                        v-if="client.profilePicURL || val"
                        :src="client.profilePicURL"
                        :alt="client.name"
                    />
                    <v-btn
                        v-else
                        class="mx-2"
                        fab
                        dark
                        large
                        color="grey darken-1"
                    >
                        <v-icon rounded large>mdi-office-building</v-icon>
                    </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="mt-3">{{
                        client.name
                    }}</v-list-item-title>
                    <v-list-item class="pa-0">
                        <v-list-item-icon class="mt-2 mb-0">
                            <v-icon>mdi-map-marker</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle class="">{{
                            client.city
                        }}</v-list-item-subtitle>
                    </v-list-item>
                </v-list-item-content>
            </v-list-item>
        </v-lazy>
    </v-sheet>
</template>

<script>
import { storage } from '@/services/firebase'

export default {
    name: 'CardClient',
    props: {
        client: Object,
    },
    data() {
        return {
            companyId: JSON.parse(localStorage.getItem('company')),
            folderPictures: 'clients_pictures',
            val: false,
        }
    },
    async mounted() {
        if (this.client.picture) {
            const path = `${this.companyId}/${this.folderPictures}`
            const storageRef = storage().ref(path)
            const profilePicRef = storageRef.child(this.client.picture)
            await profilePicRef
                .getDownloadURL()
                .then(url => {
                    this.client.profilePicURL = url
                    this.val = true
                })
                .catch(error => {
                    this.client.profilePicURL = ''
                    error && true
                })
        }
    },
    methods: {
        openDialog() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('clientDetail')
            }
        },
    },
}
</script>
